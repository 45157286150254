.nav-blue {
    background-color: #2d7fc2;
    margin-right: -10px;
    margin-left: -10px;
}

.nav-blue.floresemprego {
    background-color: #00a776;
}

.nav-blue.preparamilitar {
    background-color: #5f7569;
}

.nav-blue.acim {
    background-color: #004742;
}

.nav-blue .nav > li > a {
    color: #ffffff;
    font-weight: 300;
}

.nav-blue .nav > li > a:hover, .nav-blue .nav > li > a:focus {
    color: #2d7fc2;
}

.navbar-myskills .navbar-header {
    margin-top: -5px !important;
}

.navbar-myskills-logo, .footer-myskills-logo {
    padding-left: 20px;
    vertical-align: super;
    text-transform: uppercase;
    font-size: 24px;
    color: #2d7fc2;
}

.footer-myskills-logo {
    vertical-align: bottom;
}

.navbar-line, .footer-line {
    width: 2px;
    height: 100%;
    background-color: #f58345;
    display: inline-block;
}

.footer-line {
    height: 50px;
    vertical-align: sub;
}

.logo-img, .footer-img {
    vertical-align: inherit;
}

.footer-img {
    vertical-align: bottom;
}

section#contact {
    margin-top: -30px;
    background: #083153;
    color: #ffffff;
}

section#contact.floresemprego {
    background: #ffffff;
    color: #00a776;
}

section#contact.preparamilitar {
    background: #ffffff;
    color: #030302;
}

section#contact.acim {
    background: #ffffff;
    color: #004742;
}

section#contact.manoelribas {
    background: #ffffff;
    color: #083153;
}

section#contact ul {
    list-style: none;
}

section#contact ul li {
    color: #ffffff;
}

.testimonials {
    background-image: url('../images/bg02.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /*background-blend-mode: soft-light;*/
}

.testimonials.floresemprego {
    background-color: rgb(49 49 49 / 87%);
    background-image: url('../images/banner_1.jpg');
    background-blend-mode: soft-light;
}

.testimonials.abrhpr {
    background-image: url('../images/abrh_banner.png');
    height: 770px;
}

.bg-white {
    background-color: #fff;
}

.testimonials.acim {
    background-color: rgb(0, 71, 66);
    background-image: url('../images/bg02.png');
    background-blend-mode: soft-light;
}

.testimonials.manoelribas {
    background-color: rgba(58, 128, 182, 0.7);
    background-image: url('../images/banner_1.jpg');
    background-blend-mode: soft-light;
}

.testimonials.preparamilitar {
    background-image: url('../images/reuniao-militar.png');
}

.testimonials.acim {
    background-image: url('../images/acim.png');
}


.imagemAvatar {
    /*background-color: rgba(0, 22, 40, 0.7);*/
    background-image: url('../images/banner-myskills-novo2.jpg');
    right: 0;
    left: 0;
    min-height: 600px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
}

.imagemAvatar.floresemprego {
    /*background-color: rgba(0, 22, 40, 0.7);*/
    background-image: url('../images/banner-myskills-novo-verde.png');
}

.imagemAvatar .text-empresa {
    text-align: right;
}

.imagemAvatar .text-pessoa {
    text-align: left;
}

.imagemAvatar .text-pessoa .banr-perfil-btn {
    float: left !important;
}

.imagemAvatar .text-empresa .banr-perfil-btn {
    float: right !important;
}

.imagemAvatar.floresemprego .banr-perfil-btn {
    color: #00a776;
    border: solid 2px #00a776;
}

.imagemAvatar.floresemprego .banr-perfil-btn:hover {
    background-color: rgb(0, 167, 142);
    color: #23343f;
}

@media (max-width: 768px) {
    .imagemAvatar .text-empresa {
        text-align: center;
    }

    .imagemAvatar .text-pessoa {
        text-align: center;
    }

    .imagemAvatar .text-empresa .banr-perfil-btn {
        float: none !important;
        width: 100%;
    }

    .imagemAvatar .text-pessoa .banr-perfil-btn {
        float: none !important;
        width: 100%;
    }
}

.imagemChart {
    background-color: #3F83C2;
    background-image: url('../images/myskills-competencias.png');
    background-size: cover;
    background-position: center;
}

.banr-perfil-text {
    color: #001628B2;
    /*background-color: rgba(0, 22, 40, 0.7);*/
    width: 70%;
    display: inline-block;
    margin-top: 5%;
    /*margin-block-start: 5em;*/
    font-weight: 300;
}

.banr-perfil-text span {
    color: #f9c43a;
    text-transform: uppercase;
    font-weight: 600;
}

.banr-perfil-line {
    height: 120px;
    width: 3px;
    /*background-color: #ffffff;*/
    display: inline-block;
    vertical-align: bottom;
}

.banr-perfil-img {
    width: 10%;
    display: inline-block;
    vertical-align: bottom;
}

.banr-perfil-btn {
    width: 50%;
    /*margin: auto;*/
    color: #f58220;
    border: solid 2px #f58220;
    padding: 8px;
    text-transform: uppercase;
    /*margin-block-start: 0.67em;*/
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
}

.banr-perfil-btn:hover {
    background-color: rgb(245, 130, 32);
    color: #23343f;
}

.banr-perfil-sub h1, .banr-perfil-sub h2, .banr-vagas-sub h1 {
    color: #2d7fc2;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
}

.banr-perfil-sub h1, .banr-vagas-sub h1 {
    font-size: 38px;
}

.banr-perfil-sub h2 {
    font-size: 32px;
    margin-block-start: 0em;
    margin-block-end: 1.2em;
}

.banr-perfil-sub span, .banr-vagas-sub span {
    color: #f58220;
    font-weight: 600;
}

.banr-vagas {
    right: 0;
    left: 0;
    height: 600px;
    background-color: rgba(0, 22, 40, 0.7);
    background-image: url('../images/banner_3.jpeg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
}

.banr-vagas-text {
    width: 80%;
    margin: auto;
}

.banr-vagas-text h1, .banr-vagas-text h2 {
    color: #ffffff;
}

.banr-vagas-text h1 {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 900;
    margin-block-start: 2.5em;
}

.banr-vagas-text h2 {
    font-size: 32px;
    text-transform: initial;
}

.banr-vagas-text span {
    color: #ffcb05;
    font-weight: 600;
}

.banr-acesso {
    background-color: #cfe9ff;
    margin: 0 -35px 0 -35px;
    padding-top: 5px;
}

.banr-acesso h3 {
    color: #2d7fc2;
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.banr-acesso img {
    width: 100px;
    display: inline-block;
}

.opcoes-feed {
    background-color: #ffffff;
    margin: 0 -35px 0 -35px;
    padding: 16px;
}

.opcoes-feed .box-opcao {
    background-color: #e4ecfb;
    text-align: center;
    padding: 16px;
    cursor: pointer;
}

.opcoes-feed .box-opcao:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
    box-shadow: 0 3px 7px 0 #a8a8a8;
}

.opcoes-feed .box-opcao span {
    color: #2f2f2f;
    font-weight: 300;
    font-size: 14px;
    display: block;
}

.opcoes-feed .box-opcao img {
    margin: auto;
}

.slick-prev, .slick-next {
    top: 40% !important;
}

.slick-prev {
    left: -100px !important;
}

.slick-prev, .slick-prev:before {
    background-image: url('../images/left-arrow.png');
    background-size: 100px 100px;
    display: inline-block;
    width: 100px;
    height: 100px;
    content: "" !important;
}

.slick-next, .slick-next:before {
    background-image: url('../images/right-arrow.png');
    background-size: 100px 100px;
    display: inline-block;
    width: 100px;
    height: 100px;
    content: "" !important;
}

.price-product {
    padding: 5px;
    background-color: #2d7fc2;
}

.price-product p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 300;
    font-size: 18px;
}

.price-product p span {
    font-weight: 600;
}

.ibox:hover {
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
    box-shadow: 0 3px 7px 0 #a8a8a8;
    cursor: pointer;
}

.ibox:hover .price-product p {
    color: #ffffff !important;
}

.feed-container {
    background-color: #ffffff;
}

.feed-profile-box {
    height: 700px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.feed-profile {
    width: 75%;
    float: right;
    border: solid 1px #f1f1f1;
    border-bottom: solid 24px #a7a7a7;
    margin: 16px 16px 0px 16px;
}

.feed-profile .media-body .name {
    color: #18b1f0;
    font-size: 24px;
    text-transform: capitalize;
}

.feed-profile .media-body .date {
    color: #141111;
    font-size: 16px;
    font-weight: 600;
}

.corporate-body {
    /*background-color: #d1e7f8;*/
    padding: 30px 16px;
}

.sine-box {
    background-color: #ffffff;
    padding: 16px;
    width: fit-content;
}

.area-restrita {
    width: 322px;
    margin-top: 16px;
}

.area-restrita .header {
    background-color: #2d7fc2;
    padding: 16px;
}

.area-restrita .header h1,
.area-restrita .header h2 {
    color: #ffffff;
    font-weight: 600;
    margin-block-start: 0;
    margin-block-end: 0;
}

.area-restrita .header h1 {
    font-size: 30px;
}

.area-restrita .header h2 {
    font-size: 24px;
}

.area-restrita .btn {
    background-color: #f5821f;
    padding: 16px;
    width: 100%;
    border-radius: 0px;
}

.area-restrita .btn a {
    color: #ffffff;
    font-weight: 300;
    font-decoration: none;
}

.feed-container .feed-header h1,
.corporate-container .corporate-header h1,
.feed-container .feed-header h2,
.corporate-container .corporate-header h2 {
    font-weight: 600;
    text-transform: uppercase;
}

.feed-container .feed-header h1,
.corporate-container .corporate-header h1 {
    font-size: 32px;
}

.feed-container .feed-header h2,
.corporate-container .corporate-header h2 {
    font-size: 28px;
}

.feed-container .feed-header,
.corporate-container .corporate-header {
    text-align: center;
    padding: 16px;
}

.feed-container .feed-header h1,
.feed-container .feed-header h2,
.corporate-container .corporate-header h1,
.corporate-container .corporate-header h2 {
    margin-block-start: 0;
}

.feed-container .feed-header h1,
.corporate-container .corporate-header h2 {
    margin-block-end: 0;
}

.feed-container .feed-header h2,
.corporate-container .corporate-header h1,
.corporate-container .corporate-header h2 {
    color: #ffffff;
}

.feed-container .feed-header h1 {
    color: #c7eafb;
}

.feed-container .feed-header {
    margin-left: -20px;
    background: #2d7fc2;
}

.corporate-container .corporate-header {
    margin-right: -20px;
    background: #99caf2;
}

.want-you {
    background-color: #3F83C2;
    background-image: url('../images/want-you.jpg');
    background-size: cover;
    background-position: bottom;
}

.vagas-bg {
    background-image: url('../images/city.jpg');
    background-size: cover;
    background-position: bottom;
}

.woman-work {
    background-color: #3F83C2;
    background-image: url('../images/woman-work.jpg');
    background-size: cover;
    background-position: bottom;
}


.um-clique-distancia {
    background-color: #376da4;
    background-image: url('../images/clique-teste.png');
    background-size: cover;
    right: 0;
    left: 0;
    color: #ffffff;

}


.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

@media (min-width: 992px) {
    .modal-lg {
        width: 1000px !important;
    }
}

.gray-section {
    background: #f4f4f4;
    margin-top: 60px;
}

.contact {
    padding-top: 20px;
    right: 0;
    left: 0;
}

.foto-perfil img {
    width: 150px;
    height: 150px;
    box-shadow: 0 0 0 1px #e5e5e5;
}

.foto-perfil span {
    display: none;
    position: absolute;
    width: 100%;
    height: 50px;
    top: 45%;
    left: 0;
    bottom: 0;
    right: 0;
    color: #373737;
}

.foto-perfil a:hover span {
    display: block;
}

.foto-perfil a:hover img {
    opacity: 0.2;
    filter: alpha(opacity=20); /* For IE8 and earlier */
}

.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.cadastre-se {
    position: fixed;
    right: 10px;
    top: 20%;
    width: 300px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    border: 3px solid #1c84c6;
    z-index: 999;
}

.force-show {
    display: block !important;
}

.feed-sm {
    display: none !important;
}

.feed-lg {
    display: block !important;
}

@media (max-width: 640px) {
    .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5 {
        padding: 0 !important;
    }

    .feed-sm {
        display: block !important;
    }

    .feed-lg {
        display: none !important;
    }

    .opcoes-feed .box-opcao {
        margin: 16px 0;
    }

    .area-restrita {
        margin-top: 16px;
    }

    .nav > li {
        display: inline-block;
    }

    .banr-perfil-text, .banr-vagas-text {
        font-size: 24px;
        width: 90%;
    }

    .banr-perfil-text, .banr-vagas-text h1 {
        margin-block-start: 1em;
    }

    .banr-perfil-line {
        height: 2px;
        width: 90%;
        display: block;
        margin: auto;
    }

    .banr-perfil-img {
        width: 50%;
    }

    .banr-perfil-btn {
        width: 90%;
    }

    .banr-perfil-sub h1,
    .banr-vagas-text h1,
    .banr-vagas-text h2,
    .feed-container .feed-header h1,
    .corporate-container .corporate-header h1 {
        font-size: 30px;
    }

    .banr-perfil-sub h2,
    .banr-vagas-sub h1,
    .feed-container .feed-header h2,
    .corporate-container .corporate-header h2 {
        font-size: 24px;
    }

    .col-sm-12.slick-slide,
    .col-sm-12.slick-slide > .col-md-12 {
        padding: 0;
    }

    .banr-head.sem-margem {
        margin-top: unset !important;
    }

    .feed-profile {
        width: 100%;
    }
}

@media (max-width: 640px) and (orientation: landscape) {
    .sine-box {
        display: inline-block;
    }

    .sine-box img {
        width: 233px;
    }

    .area-restrita {
        margin-top: 0px;
        margin-bottom: 16px;
        float: right;
    }

    .footer-myskills-logo {
        vertical-align: top;
    }

    .footer-line {
        height: 100px;
        vertical-align: super;
        float: right;
    }

    .footer-img {
        vertical-align: bottom;
        width: 95px;
    }
}

@media (max-width: 360px) and (orientation: portrait) {
    .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5 {
        width: 100% !important;
    }
}


#field_search_pessoa::-webkit-input-placeholder { /* Edge */
    color: #ffffcc;
}

#field_search_pessoa:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffcc;
}

#field_search_pessoa::placeholder {
    color: #ffffcc;
}

.div-centralizada {
    display: flex;
    justify-content: center;
}

/*.laptop-video {*/
/*    position: relative;*/
/*    padding-top: 25px;t*/
/*    padding-bottom: 67.5%;*/
/*    height: 0;*/
/*}*/

/*.laptop-video iframe {*/
/*    box-sizing: border-box;*/
/*    background: url('../images/notebook.png') center center no-repeat;*/
/*    background-size: contain;*/
/*    padding: 45% 12% 45%;*/
/*    position: absolute;*/
/*    top: -30%;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
